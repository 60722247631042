const genres = [
  {
    id: 0,
    name: "tianmeimedia",
  },
  {
    id: 1,
    name: "media",
  },
  {
    id: 2,
    name: "korean",
  },
  {
    id: 3,
    name: "teen",
  },
  {
    id: 4,
    name: "japanese",
  },
  {
    id: 5,
    name: "korean",
  },
  {
    id: 14,
    name: "Fantasy",
  },
  {
    id: 36,
    name: "History",
  },
  {
    id: 27,
    name: "Horror",
  },
  {
    id: 10402,
    name: "Music",
  },
  {
    id: 9648,
    name: "Mystery",
  },
  {
    id: 10749,
    name: "Romance",
  },
  {
    id: 878,
    name: "Science Fiction",
  },
  {
    id: 10770,
    name: "TV Movie",
  },
  {
    id: 53,
    name: "Thriller",
  },
  {
    id: 10752,
    name: "War",
  },
  {
    id: 37,
    name: "Western",
  },
];

export default genres;
