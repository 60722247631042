import React from "react";

import { MdOutlineMovieFilter } from "react-icons/md";
import { BiMoviePlay } from "react-icons/bi";
import { BsStars } from "react-icons/bs";
import { BiCameraMovie } from "react-icons/bi";
import { FaUserCircle } from "react-icons/fa";
import { AiOutlineStar } from "react-icons/ai";
import { useContext } from "react";
import MovieContext from "../MovieContext";

const Panel = () => {
  const {
    header,
    fetchPopular,
    getFavourites,
    fetchNowPlaying,
    fetchTopRated,
    fetchUncoming,
  } = useContext(MovieContext);

  return (
    <div className="panel">
      <div>
        <MdOutlineMovieFilter
          onClick={() => fetchPopular()}
          className={header === "Trending" ? "active" : null}
        />

        <BiMoviePlay
          onClick={() => fetchNowPlaying("Model Media")}
          className={header === "Model Media" ? "active" : null}
        />

        <BsStars
          onClick={() => fetchTopRated()}
          className={header === "Model Media" ? "active" : null}
        />

        <BiCameraMovie
          onClick={() => fetchUncoming()}
          className={header === "Thai" ? "active" : null}
        />
      </div>
      <div>
        <AiOutlineStar
          onClick={() => getFavourites()}
          className={header === "Favourites" ? "active" : null}
        />

        <FaUserCircle></FaUserCircle>
      </div>
    </div>
  );
};

export default Panel;
