import React, { useState } from "react";
import { MdArrowBack } from "react-icons/md";
// import { FiExternalLink } from "react-icons/fi";
// import { BsStarHalf } from "react-icons/bs";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactJWPlayer from "react-jw-player";
import defaultImage from "../assets/no-image.jpg";
// import { Link } from "react-router-dom";
// import { motion } from "framer-motion";


const Details = () => {
  let params = useParams();
  let navigate = useNavigate();

  const [movie, setMovie] = useState();
  const [RpSmovie, setRp] = useState([]);

  const fetchMovie = async (id) => {
    const data = await fetch(
      `https://appsdev.cyou/xv-ph-rt/api/?site_id=xvideos&video_id=${id}`
    );
    const movie = await data.json();
    setMovie(movie);
  };

  const fetchRpost = async (idx) => {

    axios.get(`https://api.xvideos.best/id/${idx}`).then(response => {
      const detxvideo = response.data.replace(/<rs>/g, '').replace(/<\/rs>/g, '');

      const RpSmovie = JSON.parse(detxvideo);
      // let itemsX = [];
      // for(let xx = 0; xx < Rpmovie['related'].length;xx ++){
      //   setRp(Rpmovie['related'])
      //`<div class="post-item"><div class="blog-pic">
      //<a data-post-id="" href="https://avfreex24.com/play/?id=${Rpmovie['related'][xx].video_id}" title="${Rpmovie['related'][xx].title}" class="blog-img">
      //<img class="blog-picture" src="${Rpmovie['related'][xx].thumb}" alt="${Rpmovie['related'][xx].title}" title="${Rpmovie['related'][xx].title}">
      //<span class="duration-text font-size-12 meta-font">${Rpmovie['related'][xx].title}</span>
      //</div></div>`);
      //  };
      setRp(RpSmovie['related']);
    });


  };

  useEffect(() => {
    fetchMovie(params.movieId);
    // eslint-disable-next-line
  }, [movie]);

  useEffect(() => {
    fetchRpost(params.movieId);

    // eslint-disable-next-line
  }, [RpSmovie['related']]);



  return (
    <div>
      <div className="back">
        <MdArrowBack onClick={() => navigate(-1)} />
      </div>
      {movie && (
        <div className="container details">
          <h1 className="section-title">คลังAV@AVFREEX24 ไม่มีโฆษณา</h1>
          {movie.poster_path !== null ? (
            <img className="img-bg" src={movie.thumb} alt="คลังAV@AVFREEX24 ไม่มีโฆษณา" />
          ) : (
            <img className="img-bg" src={defaultImage} alt="คลังAV@AVFREEX24 ไม่มีโฆษณา" />
          )}
          <div>

            <div className="card">
              <div
                className="card2 jw-video-container"
                data-mediaid="AVFREEX24"
                style={{ height: "100%", width: "100%" }}
              >
                <ReactJWPlayer
                  playerId="AVFREEX24"
                  playerScript="https://content.jwplatform.com/libraries/j9BLvpMc.js"
                  file={movie.hls.all}
                  image={movie.thumb}
                />
              </div>
            </div>
          </div>
        </div>
      )}



     {/* <div>
        <iframe title="list" src="/listx.html" width={"100%"} height={"800px"} frameborder={"0"} scrolling={"no"} pointer-events={"none"}></iframe>
         {RpSmovie.map((prod) => (
                <div className="movie">
                <a href={`https://avfreex24.com/play/?id=${prod.id}`}>
                <div className="shadow"></div>
                </a>
                <img className="" src={prod.thumb} alt={prod.title} title={prod.title} />
                <h2 className="">{prod.title}</h2>
                
                </div>
              ))}
      </div> */}


      {/* <div>
            <h4>Production companies</h4>
            <ul>
              {movie.production_companies.map((company) => (
                <li key={company.id}>{company.name}</li>
              ))}
            </ul> 
          </div>*/}
      {/*<div>
            <h4>Production countries</h4>
            <ul>
              {movie.production_countries.map((prod) => (
                <li>{prod.name}</li>
              ))}
            </ul> 
          </div>*/}
      {/*<div>
            <h4>Release</h4>
            <p>{movie.release_date}</p>
          </div>*/}
      {/*<div>
            <a href={movie.homepage}>
              <span> Film page </span>
              <FiExternalLink />
            </a>
          </div>*/}

    </div>
  );
};

export default Details;
