import React from "react";
import { Link } from "react-router-dom";
import { useContext } from "react";
import MovieContext from "../MovieContext";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import { motion } from "framer-motion";
import defaultImage from "../assets/no-image.jpg";

function Movie({ movie }) {
  const { addToFavourites, isFav } = useContext(MovieContext);

  return (
    <motion.div
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      layout
      className="movie"
    >
      {isFav(movie.video_id) ? (
        <AiOutlineStar onClick={() => addToFavourites(movie)} />
      ) : (
        <AiFillStar onClick={() => addToFavourites(movie)} />
      )}
      <Link to={`/movie/${movie.video_id}`} key={movie.video_id}>
        <div className="shadow"></div>
      </Link>
      {movie.thumb !== null ? (
        // eslint-disable-next-line
        <img className="card2" src={movie.thumb} />
      ) : (
        <img className="card2" src={defaultImage} />
      )}
      <h2>{movie.title}</h2>
    </motion.div>
  );
}

export default Movie;
