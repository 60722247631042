import { createContext, useState } from "react";
import { useLocalStorage } from "./useLocalStorage";

const MovieContext = createContext();

export function MovieProvider({ children }) {
  const [movies, setMovies] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [activeGenre, setActiveGenre] = useState(0);
  const [header, setHeader] = useState("Trending");

  // localstorage state
  const [favourites, setFavourites] = useLocalStorage("fav", []);

  const fetchPopular = async () => {
    const data = await fetch(
      `https://api.avfreex24.com/xvideo?key=Tianmei+Media`
      //"https://api.themoviedb.org/3/movie/popular?api_key=b454aa11fb4b5fc5b515d2e80a898a1c&language=en-US&page=1"
    );
    const movies = await data.json();
    setMovies(movies.XVID_ITEMS);
    setFiltered(movies.XVID_ITEMS);
    setHeader("Trending");
    setActiveGenre(0);
  };

  const fetchSearch = async (query) => {
    const data = await fetch(
      `https://api.avfreex24.com/xvideo?key=${query}`
    );
    const movies = await data.json();
    setMovies(movies.XVID_ITEMS);
    setFiltered(movies.XVID_ITEMS);
    setHeader(`ผลการค้นหา "${query}"`);
    setActiveGenre(0);
  };

  const fetchNowPlaying = async (activeGenre) => {
    const data = await fetch(
      `https://api.avfreex24.com/xvideo?key=${activeGenre}`
    );
    const movies = await data.json();
    setMovies(movies.XVID_ITEMS);
    setFiltered(movies.XVID_ITEMS);
    setHeader(`หมวดหมู่ ${activeGenre}`);
    setActiveGenre(0);
  };

  const fetchTopRated = async () => {
    const data = await fetch(
      `https://api.avfreex24.com/xvideo?key=Top`
    );
    const movies = await data.json();
    setMovies(movies.XVID_ITEMS);
    setFiltered(movies.XVID_ITEMS);
    setHeader("Top rated");
    setActiveGenre(0);
  };

  const fetchUncoming = async () => {
    const data = await fetch(
      `https://api.avfreex24.com/xvideo?key=%E0%B9%84%E0%B8%97%E0%B8%A2&sort=uploaddate`
    );
    const movies = await data.json();
    setMovies(movies.XVID_ITEMS);
    setFiltered(movies.XVID_ITEMS);
    setHeader("Thai");
    setActiveGenre(0);
  };

  const addToFavourites = (movie) => {
    let isOnArray = false;
    favourites.map((fav) => {
      if (fav.id === movie.video_id) {
        isOnArray = true;
      }
    });

    if (isOnArray) {
      setFavourites(favourites.filter((fav) => fav.id !== movie.video_id));
    } else {
      setFavourites((prevState) => [...prevState, movie]);
    }
  };

  const getFavourites = () => {
    setMovies(favourites);
    setFiltered(favourites);
    setHeader("แอพแดง favourites");
    setActiveGenre(0);
  };

  const isFav = (id) => {
    let fav = favourites.filter((fav) => fav.id === id);
    return fav.length === 0 ? true : false;
  };

  return (
    <MovieContext.Provider
      value={{
        header,
        setHeader,
        addToFavourites,
        filtered,
        setFiltered,
        fetchPopular,
        movies,
        setMovies,
        activeGenre,
        setActiveGenre,
        fetchSearch,
        getFavourites,
        isFav,
        fetchNowPlaying,
        fetchTopRated,
        fetchUncoming,
      }}
    >
      {children}
    </MovieContext.Provider>
  );
}

export default MovieContext;
